class Canvas
  constructor: (element) ->
    @canvas = element
    @setUpCanvas()

  setUpCanvas: () ->
    @context = @canvas.getContext('2d')

    @width = @canvas.clientWidth
    @height = @canvas.clientHeight

    @centerX = @width/2
    @centerY = @height/2

  reset: () ->
    if (@canvas.width != @width || @canvas.height != @height)
      @canvas.width = @width
      @canvas.height = @height

    @context.clearRect(0, 0, @width, @height)

  displayNewDimension: () ->
    $(@canvas).data('display-new-dimension') != undefined

  drawCircle: (x, y, radius) ->
    @context.beginPath()
    @context.arc(x, y, radius, 0, Math.PI*2)

  strokeCircle: (x, y, radius, style) ->
    @drawCircle(x, y, radius)
    @context.strokeStyle = style
    @context.stroke()

  fillCircle: (x, y, radius, style) ->
    @drawCircle(x, y, radius)
    @context.fillStyle = style
    @context.fill()

  strokeLine: (beginX, beginY, endX, endY, style) ->
    @context.beginPath()
    @context.moveTo(beginX, beginY)
    @context.lineTo(endX, endY)
    @context.strokeStyle = style
    @context.stroke()

  strokeRectangle: (beginX, beginY, width, height, style) ->
    @context.strokeStyle = style
    @context.strokeRect(beginX, beginY, width, height)

  strokeRoundedRectangle: (beginX, beginY, width, height, radius, style) ->
    @context.beginPath()
    @context.moveTo(beginX + radius, beginY)

    @context.lineTo(beginX + width - radius, beginY)
    @context.quadraticCurveTo(beginX + width, beginY, beginX + width, beginY + radius)

    @context.lineTo(beginX + width, beginY + height - radius)
    @context.quadraticCurveTo(beginX + width, beginY + height, beginX + width - radius, beginY + height)

    @context.lineTo(beginX + radius, beginY + height)
    @context.quadraticCurveTo(beginX, beginY + height, beginX, beginY + height - radius)

    @context.lineTo(beginX, beginY + radius)
    @context.quadraticCurveTo(beginX, beginY, beginX + radius, beginY)

    @context.strokeStyle = style
    @context.stroke()

  rotateAroundPoint: (x, y, angle) ->
    @context.translate(x, y)
    @context.rotate(angle)
    @context.translate(-x, -y)

  rotateAroundCenter: (angle) ->
    @rotateAroundPoint(@centerX, @centerY, angle)


export default Canvas
