class ActionComplete
  @init: () ->
    new ActionComplete

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $(document).on 'change', '.toggle-action-complete', (e) ->
      e.preventDefault()
      Rails.fire(this, 'submit')

    $(document).on 'ajax:success', '.toggle-action-complete', (e) ->
      data = e.detail[0]
      checkbox = $(e.target).find('input:checkbox').checked = data.complete

export default ActionComplete
