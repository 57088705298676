class LiveTimesheetCost
  @init: () ->
    new LiveTimesheetCost

  constructor: () ->
    return unless $('.live-timesheet-cost').length > 0
    @cost = $('.live-timesheet-cost span')
    @billable_hours = $('.billable-hours')
    @hourly_rate = $('.hourly-rate')
    @extra_costs = $('.extra-costs')
    @attachEvents()
    @updateCost()

  attachEvents: () ->
    @billable_hours.on 'change', @updateCost
    @hourly_rate.on 'change', @updateCost
    @extra_costs.on 'change', @updateCost

  updateCost: () =>
    billable_hours = Number(@billable_hours.val())
    hourly_rate = Number(@hourly_rate.val())
    extra_costs = Number(@extra_costs.val())

    total = ((billable_hours * hourly_rate) + extra_costs).toFixed(2)

    @cost.text("£#{total}")

export default LiveTimesheetCost
