/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

const images = require.context('../images', true);

import '@rails/actiontext';
import 'foundation-sites';
import 'plugins/jquery-ui.min.css';
import 'plugins/jquery-ui.min.js';
import 'plugins/jquery-ui.structure.min.css';
import 'jquery.cycle2';
import 'plugins/auto-complete.css';
import 'plugins/auto-complete.min.js';

import 'stylesheets/application.scss';
import 'javascripts/application.js';
