class StarRating
  @init: () ->
    new StarRating

  constructor: () ->
    @attachEvents()
  attachEvents: () ->
    self = @
    $('.star-rating').on 'touchstart', ->
      self.setRatingClass(this)
      self.reflectionInputAction(this)

    $('.star-rating li').click ->
      self.setRatingClass(this)
      self.reflectionInputAction(this)

    $('.cancel').click (event) ->
      event.preventDefault()
      self.cancelResetBox(this)

  setRatingClass: (li) =>
    $(li).addClass('assigned-rating').siblings().removeClass('assigned-rating')
    $(li).closest('.star-rating').find('.star-rating-input').val($(li).data('rating'))
    $(li).closest('form').find('input[type=submit]').removeAttr('disabled')

  reflectionInputAction: (item) ->
    $(item).parents('.reflection-item').addClass('active')

  cancelResetBox: (button) ->
    parent = $(button).parents('.reflection-item')
    parent.toggleClass('active')
    parent.find('.assigned-rating').removeClass('assigned-rating')
export default StarRating
