class FocusWheelLabels
  constructor: (dimensions, canvasWidth) ->
    @dimensions = dimensions
    @canvasWidth = canvasWidth

  render: () ->
    dimensionAngle = (2 * Math.PI) / @dimensions.length
    dimensionCount = @dimensions.length

    $labels = $('.dimension-label-wrapper')
    $labels.hide()

    @dimensions.forEach (dimension, index) =>
      i = index + 1
      labelClass = 'dimension-label label-count--'+dimensionCount+'--'+i

      dx = Math.sin(dimensionAngle * index) * @canvasWidth/2
      dy = Math.cos(dimensionAngle * index) * @canvasWidth/2

      $label = $("#dimension-label-#{dimension.id}")

      $label.show().css({ position: 'absolute', left: dx, top: -dy })
      $label.children('div').removeClass().addClass(labelClass)
      labelHeight = $label.children('div').height()
      labelWidth = $label.children('div').outerWidth()

      if $label.text().trim().length > 12
        $label.children('div').addClass('multiline')

export default FocusWheelLabels
