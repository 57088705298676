class Favorites
  @init: () ->
    new Favorites

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $(document).on 'ajax:success', '.favorite, .unfavorite', (e) ->
      data = e.detail[0]
      link = $(e.target)
      link.removeClass('favorite unfavorite')
      link.addClass(if data.favorite then 'unfavorite' else 'favorite')

      sortable = link.closest('[data-sort]')
      if sortable.length > 0
        sortable.attr('data-sort', data.favorite)


export default Favorites
