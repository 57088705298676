class ChatForm
  @init: () ->
    new ChatForm

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.creating-chat-create-timesheet').on 'click', @copyDetailsIntoTimesheetFields


    $('#meeting-fields-button .button').on 'click', @setMeetingRequired

    $('#meeting-fields-close').on 'click', @removeMeetingRequired

    setTimeout( () ->
      if location.hash == '#create-meeting'
        $('#meeting-fields-button .button').trigger('click')
      else
        $('.meetings-form-headline').hide()

    , 200)

  setMeetingRequired: () =>
    $('#program_content_chat_meeting_date').attr('required','required')
    $('.meetings-form-headline').show()
    $('.chats-form-headline').hide()

  removeMeetingRequired: () =>
    $('#program_content_chat_meeting_date').removeAttr('required')
    $('.chats-form-headline').show()
    $('.meetings-form-headline').hide()
      
  copyDetailsIntoTimesheetFields: () =>
    $('#program_content_chat_timesheet_entry_date').val($('#program_content_chat_meeting_date').val())

    chat_topic = $('#program_content_chat_title').val()
    meeting_attendees = $('#program_content_chat_meeting_attendees').val()
    meeting_location = $('#program_content_chat_meeting_location').val()
    
    timesheet_title = 'Meeting'
    if meeting_attendees.length > 0
      timesheet_title += " with #{meeting_attendees}"
    if meeting_location.length > 0
      timesheet_title += " at #{meeting_location}"

    $('#program_content_chat_timesheet_entry_title').val(timesheet_title)

export default ChatForm
