import Canvas from 'javascripts/app/canvas'

class SparkLine
  constructor: (container, data) ->
    @container = container
    @data = data
    @canvas = new Canvas($(container).find('canvas')[0])
    @attachEvents()
    @render()

  attachEvents: () ->
    $(window).resize =>
      @resize()

    $('#dimension-history-content').on 'off.zf.toggler', =>
      @resize()

  render: () ->
    @canvas.reset()
    @topPad = 0
    @pad = 2
    @width = @canvas.width - (@pad * 2)
    @height = @canvas.height - (@pad * 2) - @topPad
    @drawBorder()
    @renderRatings()
    @renderTargets()

  setSizes: () ->
    @width = @canvas.width
    @height = @canvas.height
    @centerX = @canvas.centerX
    @centerY = @canvas.centerY

  resize: () ->
    @canvas.setUpCanvas()
    if @width != @canvas.width
      @setSizes()
      @render()

  renderRatings: () ->
    @canvas.context.beginPath()

    entries = @ratingEntries()

    previousPoint = [@pad, @height+@pad+@topPad]
    firstPoint = @ratingPoint(entries[0].date, 0)

    @canvas.context.moveTo(firstPoint[0], firstPoint[1])

    entries.forEach (thing, index) =>
      newPoint = @ratingPoint(thing.date, thing.rating)

      @canvas.context.lineTo(newPoint[0], previousPoint[1])
      @canvas.context.lineTo(newPoint[0], newPoint[1])

      previousPoint = newPoint

    @canvas.context.lineTo(@width+@pad, previousPoint[1])
    @canvas.context.lineTo(@width+@pad, @height+@pad+@topPad)
    @canvas.context.closePath()
    @canvas.context.fillStyle = @getFillGradient()
    @canvas.context.strokeStyle = '#4F7FE1'
    @canvas.context.fill()
    @canvas.context.stroke()

  renderTargets: () ->
    @targetEntries().forEach (thing, index) =>
      point = @ratingPoint(thing.date, '10')
      @canvas.strokeLine(point[0], @pad + @topPad, point[0], @height+@pad+@topPad, '#4F7FE1')
      # image = document.getElementById('spark-flag-icon')
      # imageSize = 14
      # @canvas.context.drawImage(image, point[0] - imageSize/2, @pad + 6, imageSize, imageSize)

  ratingPoint: (date, rating) ->
    yPoint = @pad + @topPad + ((10 - parseInt(rating)) * (@height/10))

    date = new Date(date).getTime()

    daysDifference = (date - @startDate()) / (1000*60*60*24)
    xPoint = @pad + ((daysDifference / @totalDays()) * @width)

    [xPoint, yPoint]


  startDate: () ->
    new Date($(@container).data('start-date')).getTime()

  endDate: () ->
    new Date().setHours(0,0,0,0)

  totalDays: () ->
    oneDay = 1000*60*60*24
    Math.round((@endDate() - @startDate()) / oneDay)

  ratingEntries: () ->
    @cachedRatingEntries = @cachedRatingEntries || @data.filter (entry) -> entry.type == 'rating'

  targetEntries: () ->
    @cachedTargetEntries = @cachedTargetEntries || @data.filter (entry) -> entry.type == 'target'

  ratingDates: () ->
    @cachedRatingDates = @cachedRatingDates || @ratingEntries().map (entry) -> Date.parse(entry.date)

  getFillGradient: () ->
    gradient = @canvas.context.createLinearGradient(0, @pad+@topPad, 0, @height+@pad+@topPad)
    gradient.addColorStop(0, '#DCE5F9')
    gradient.addColorStop(1, 'white')
    gradient

  drawBorder: () ->
    @canvas.strokeRoundedRectangle(@pad, @pad+@topPad, @width, @height, 2, '#E2E2E2')

export default SparkLine
