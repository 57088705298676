class ResourcesGridListToggle
  @init: () ->
    new ResourcesGridListToggle

  constructor: () ->
    @attachEvents()
  attachEvents: () ->
    self = @
    $('.resources-list-view li').click (event) ->
      if !$(this).hasClass('active')
        listType = $(this).data('list-type')
        $('.resources-list-view .active').removeClass('active')
        $(this).toggleClass('active')

        if listType == 'grid'
          $('.resources-items-list').removeClass('list')
        else
          $('.resources-items-list').removeClass('grid')
        $('.resources-items-list').addClass(listType)
      else

    $(window).resize () ->
      activeListType = $('.resources-list-view .active').data('list-type')

      if $(window).width() < 641
        $('.resources-items-list').removeClass('list').addClass('grid')
      else
        $('.resources-items-list').removeClass('list')
        $('.resources-items-list').removeClass('grid')
        $('.resources-items-list').addClass(activeListType)


export default ResourcesGridListToggle
