class FocusColourPicker
  @init: () ->
    new FocusColourPicker

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.dimensions-color ul li').click ->
      bgColor = $(this).find('.item-color').css('backgroundColor')
      parentWrapper = $(this).parents('.dimensions-color')
      selectWrapper = $(this).parents('.focus-color-container')
      colorClass = $(this).data('color')

      previewWindow parentWrapper, bgColor
      $('.edit-dimension-list').sortable 'disable'
      $(this).addClass 'picked-color'
      $(selectWrapper).find('select option[value="' + colorClass + '"]').attr 'selected', 'selected'


    $(document).bind 'click', (e) ->
      if !$(e.target).is('.dimensions-color.active')
        $('.dimensions-color.active').removeClass 'active'
        $('.edit-dimension-list').sortable 'enable'

    $('.dimensions-color').click ->
      if !$(this).hasClass('active')
        colorClick this
      else
        $('.dimensions-color').removeClass 'active'

  colorClick = (item) ->
    item = item
    windowHeight = $(window).height()
    pickerPosition = $(item).offset()
    calcPos = windowHeight - pickerPosition.top
    $('.dimensions-color').removeClass 'active height-flag'
    if calcPos < 270
      $(item).addClass 'height-flag'

    $(item).toggleClass 'active'

  previewWindow = (parentWrapper, bgColor) ->
    $('.dimensions-color ul li').removeClass 'picked-color'
    $(parentWrapper).find('.active-color').css('background-color': bgColor).addClass 'colour-selected'





export default FocusColourPicker
