import mixitup from 'mixitup'

class OrderBySelect
  @init: () ->
    new OrderBySelect

  constructor: () ->
    if $('.sort-by-list').length > 0
      defaultSort = $('.sort-by-list').data('default-sort')
      @mixers = []
      $('.sort-by-list').each (i, e) =>
        @mixers.push(mixitup(e, load: { sort: defaultSort }, controls: { enable: false }))
    @attachEvents()

  attachEvents: () ->
    self = @
    $('.order-by-select--select_tag select').change (e) ->
      self.orderby($(this))
    $('.select_tag_switch.sort-select-trigger').change (e) ->
      self.orderby($(this))

  orderby: (item) ->
    @value = item.val()
    for mixer in @mixers
      mixer.sort @value


export default OrderBySelect
