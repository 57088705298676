class RichText
  @init: () ->
    new RichText

  constructor: () ->
    $(document).on('trix-file-accept', (e) =>
      maxFileSize = 1024 * 1024
      if (event.file.size > maxFileSize)
        event.preventDefault()
        alert("This attachment is too large. Max file size is "+@format_bytes(maxFileSize))
        return


      acceptedTypes = ['image/jpeg', 'image/png']
      if (!acceptedTypes.includes(event.file.type))
        event.preventDefault()
        alert("Only JPEG or PNG files can be attached")
        return
    )

  format_bytes: (a,b=2) ->
    if (0 == a)
      return "0 Bytes"

    c = 0 > b ? 0 : b
    d = Math.floor(Math.log(a) / Math.log(1024))
    return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + [" bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]

export default RichText
