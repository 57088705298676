class ToggleIcon
  @init: () ->
    new ToggleIcon

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.toggle-icon').click () ->
      $(this).toggleClass('on')

export default ToggleIcon
