class HabitChartHeight
  @init: () ->
    new HabitChartHeight

  constructor: () ->
    @attachEvents()
    @initSlider()
    @assignHeightClass()

  attachEvents: () ->
    $('.graph-slides').on 'cycle-after', =>
      @assignHeightClass()
      @resetBarHeight()
  initSlider: () ->
      $('.graph-slides').cycle();

  assignHeightClass: () ->
    $('.graph-slides .cycle-slide-active .bar').each (i) ->
      dataVal = $(@).data('bar-value');
      setTimeout (=>
        $(@).addClass('height-0'+dataVal)
      ), 150 * i
  resetBarHeight: () ->
    $('.graph-slides .cycle-slide:not(.cycle-slide-active)').each (i) ->
      $('[class*=height]').each (i) ->
        $(this).removeClass (index, className) ->
          (className.match (/\bheight-\S+/g) || []).join(' ');

export default HabitChartHeight
