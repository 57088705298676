class Chat
  @init: () ->
    new Chat

  constructor: () ->
    autosize($('.new-chat-message-input, .chat-message-input').find(".upload-textarea textarea"))
    @attachEvents()
    @filterIfLinkAnchorPresent()

  attachEvents: () ->
    if $('.chat-messages-list').length > 0
      $('.filter-by-select--select_tag select').on 'change', @sortChatMessages

  sortChatMessages: (e) ->
    selectedOption = $(e.target).children('option:selected').val()
    if selectedOption == '.event'
      $('.chat-messages-list li:visible').sort((a, b) ->
        date_a = new Date($(a).find('.chat-meeting-date').attr('datetime'))
        date_b = new Date($(b).find('.chat-meeting-date').attr('datetime'))
        if (date_a < date_b) then 1 else -1
      ).appendTo('.chat-messages-list')

    else if selectedOption =='.non-event'
      $('.chat-messages-list li:visible').sort((a, b) ->
        date_a = new Date($(a).find('.chat-latest-message-date').attr('datetime'))
        date_b = new Date($(b).find('.chat-latest-message-date').attr('datetime'))
        if (date_a < date_b) then 1 else -1
      ).appendTo('.chat-messages-list')

  filterIfLinkAnchorPresent: () ->
    if location.hash == '#chats-only'
      $('.filter-by-select').find('select').val('.non-event').trigger('change')
      $('.meetings-headline').hide()
      $('.both-headline').hide()
      $('.chats-headline').show()
      
    else if location.hash == '#meetings-only'
      $('.filter-by-select').find('select').val('.event').trigger('change')

      $('.meetings-headline').show()
      $('.chats-headline').hide()
      $('.both-headline').hide()
    else 
      $('.chats-headline').hide()
      $('.meetings-headline').hide()
      $('.both-headline').show()

export default Chat
