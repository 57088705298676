window.$ = window.jQuery = require("jquery");
window.autosize = require('autosize');

import 'javascripts/app/debounce'

import Autosize from 'javascripts/app/autosize'
import RatingSlider from 'javascripts/app/rating_slider'
import FocusWheel from 'javascripts/app/focus_wheel'
import SparkLine from 'javascripts/app/spark_line'
import FileUpload from 'javascripts/app/file_upload'
import ResourcesFileUpload from 'javascripts/app/resources_file_upload'
import RevealFormRow from 'javascripts/app/reveal_form_row'
import ToggleFormRow from 'javascripts/app/toggle_form_row'
import SelectInputRowChange from 'javascripts/app/select_input_row_change'
import HabitChartHeight from 'javascripts/app/habit_chart_height'
import HabitCheckboxes from 'javascripts/app/habit_checkboxes'
import StarRating from 'javascripts/app/star_rating'
import StarRatingReset from 'javascripts/app/star_rating_reset'
import DeleteConfirm from 'javascripts/app/delete_confirm'
import NewFocusForm from 'javascripts/app/new_focus_form'
import DimensionImageUpload from 'javascripts/app/dimension_image_upload'
import ResourcesGridListToggle from 'javascripts/app/resources_grid_list_toggle'
import ResourcesFiles from 'javascripts/app/resource_files'
import TeamMemberRoleUpdate from 'javascripts/app/team_member_role_update'
import ToggleIcon from 'javascripts/app/toggle_icon'
import BillingPlanChoice from 'javascripts/app/billing_plan_choice'
import 'plugins/jquery.ui.touch-punch.min.js'
import FilterFocusDimension from 'javascripts/app/filter_focus_dimension'
import Placeholders from 'javascripts/app/placeholders'
import IntroModal from 'javascripts/app/intro_modal'
import GoalsIntroModal from 'javascripts/app/goals_intro_modal'
import OrderBySelect from 'javascripts/app/order_by_select'
import FilterBySelect from 'javascripts/app/filter_by_select'
import ReflectionFrequencySelects from 'javascripts/app/reflection_frequency_selects'
import CalloutTimeout from 'javascripts/app/callout_timeout'
import FocusColourPicker from 'javascripts/app/focus_colour_picker'
import CharacterCounter from 'javascripts/app/character_counter'
import OffCanvasMenu from 'javascripts/app/off_canvas_menu'
import SortableList from 'javascripts/app/sortable_list'
import ProgramNavigationLayout from 'javascripts/app/program_navigation_layout'
import Chat from 'javascripts/app/chat'
import ResourcesURLHttpPrepend from 'javascripts/app/resources_url_http_prepend'
import RichText from 'javascripts/app/rich_text'
import TimesheetCheckboxes from 'javascripts/app/timesheet_checkboxes'
import CobrandingColorPreview from 'javascripts/app/cobranding_color_preview'
import ChatMessageScroll from 'javascripts/app/chat_message_scroll'
import FocusSidebar from 'javascripts/app/focus_sidebar'
import TableSortWithAddons from 'javascripts/app/table_sort_with_addons'
import TaggableTags from 'javascripts/app/taggable_tags'
import FakeLinks from 'javascripts/app/fake_links'
import LiveTimesheetCost from 'javascripts/app/live_timesheet_cost'
import TimesheetsTable from 'javascripts/app/timesheets_table'
import ChatForm from 'javascripts/app/chat_form'
import Subscriptions from 'javascripts/app/subscriptions'
import Favorites from 'javascripts/app/favorites'
import ActionComplete from 'javascripts/app/action_complete'
import SupportItems from 'javascripts/app/support_items'
import SearchResults from 'javascripts/app/search_results'

// Custom Trix Elements
import 'javascripts/trix/underline.js';
import 'javascripts/trix/url_prepender.js';

import {} from 'gems/index.js'; //in /vendor/gems

import Rails from 'rails-ujs'
Rails.start();

$(document).ready(function () {

  //initialises foundation
  $(document).foundation();

  TableSortWithAddons.init();

  let dateInputs = $('input[type=date]');
  if ((dateInputs.length > 0) && (dateInputs[0].type != 'date')) {
    dateInputs.each(function(_, input) {
      $(input).datepicker({ dateFormat: "yy-mm-dd", maxDate: new Date($(input).attr('max')) });
    });
  }

  $('.spark-line').each(function(_, element){
    new SparkLine(element, $(element).data('json'));
  });

  let focusWheelSelector = $('#focus-wheel');
  if (focusWheelSelector.length > 0) {
    window.focus_wheel = FocusWheel.init(focusWheelSelector);

    const dimensionHistoryData = JSON.parse(document.querySelector('#focus-wheel').querySelector('canvas').dataset.wheelData);

		let dimensions = [];
    if (dimensionHistoryData.length > 0) {
      dimensions = dimensionHistoryData[dimensionHistoryData.length - 1]['dimensions']
    }

    focus_wheel.render(dimensions)
  }

  Autosize.init();
  RatingSlider.init();
  FileUpload.init();
  ResourcesFileUpload.init();
  RevealFormRow.init();
  SelectInputRowChange.init();
  HabitChartHeight.init();
  HabitCheckboxes.init();
  StarRating.init();
  StarRatingReset.init();
  ToggleFormRow.init();
  DeleteConfirm.init();
  NewFocusForm.init();
  DimensionImageUpload.init();
  ResourcesGridListToggle.init();
  ResourcesFiles.init();
  TeamMemberRoleUpdate.init();
  ToggleIcon.init();
  BillingPlanChoice.init();
  FilterFocusDimension.init();
  Placeholders.init();
  OrderBySelect.init();
  FilterBySelect.init();
  ReflectionFrequencySelects.init();
  CalloutTimeout.init();
  FocusColourPicker.init();
  CharacterCounter.init();
  OffCanvasMenu.init();
  SortableList.init();
  ProgramNavigationLayout.init();
  Chat.init();
  RichText.init();
  TimesheetCheckboxes.init();
  CobrandingColorPreview.init();
  ChatMessageScroll.init();
  FocusSidebar.init();
  TaggableTags.init();
  FakeLinks.init();
  LiveTimesheetCost.init();
  TimesheetsTable.init();
  ChatForm.init();
  Favorites.init();
  ActionComplete.init();
  SearchResults.init();

  window.IntroModal = IntroModal;
  window.GoalsIntroModal = GoalsIntroModal;
  window.ResourcesURLHttpPrepend = ResourcesURLHttpPrepend;
  window.Subscriptions = Subscriptions;
  window.SupportItems = SupportItems;
});
