class RevealFormRow
  @init: () ->
    new RevealFormRow

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('[data-form-trigger]').click (e) ->
      @dataVal = $(this).data('form-trigger')
      e.preventDefault()
      $(this).addClass('active');
      $('[data-form-reveal="'+@dataVal+'"]').addClass('active')
export default RevealFormRow
