class CobrandingColorPreview
  @init: () ->
    new CobrandingColorPreview

  constructor: () ->
    $('.cobranding-color-input').each (_, element) =>
      @updateColor(element)

    @attachEvents()

  attachEvents: () ->
    $('.cobranding-color-input').on 'change', (e) =>
      @updateColor(e.target)

  updateColor: (inputElement) ->
    color = '#' + $(inputElement).val()
    $(inputElement).closest('.grid-x').find('.color-preview').css('background', color)

export default CobrandingColorPreview
