class ToggleFormRow
  @init: () ->
    new ToggleFormRow

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    self = @

    $('[data-form-toggle-trigger]').click (e) ->
      data = $(this).data('form-toggle-trigger')
      self.toggleSwitch(e, data);

    $('[data-form-toggle-cancel]').click (e) ->
      data = $(this).data('form-toggle-cancel')
      self.toggleSwitch(e, data);

    $('[data-form-toggle-edit] textarea, [data-form-toggle-edit] input:not([type="password"])').on 'keyup', ->
      data = $(this).parent().data('form-toggle-edit')
      inputVal = $(this).val()
      $('[data-form-toggle-content="'+dataVal+'"]').html(inputVal)

    $(document).on 'click', @closeOverlaysIfClickingElsewhere

  toggleSwitch: (event, dataVal) ->
    event.preventDefault()

    table_row = $(event.target).closest('tr')

    $('[data-form-toggle-trigger="'+dataVal+'"]').toggleClass('active')

    if table_row.length > 0
      # program_row
      overlay = $('[data-form-toggle-content="'+dataVal+'"]')

      if overlay.hasClass('active')
        @closeProgramRowOverlay(overlay, table_row)
      else
        @openProgramRowOverlay(overlay, table_row)

    else
      # program_card
      $('[data-form-toggle-content="'+dataVal+'"]').toggleClass('active')
      $('[data-form-toggle-edit="'+dataVal+'"]').toggleClass('active')
      $('[data-form-toggle-hide="'+dataVal+'"]').toggle()

  openProgramRowOverlay: (overlay, table_row) ->
    @closeOpenProgramRowOverlays()
    top_position = table_row.position().top
    first_cell = table_row.find('td').first()

    if $('#stickThis').hasClass('stick')
      top_position = top_position + 41

    overlay.css(
      position: 'absolute'
      top: top_position
      left: first_cell.outerWidth()
      height: table_row.outerHeight()
      width: table_row.outerWidth() - first_cell.outerWidth()
    )

    overlay.addClass('active')

  closeProgramRowOverlay: (overlay, table_row) ->
    overlay.closest('.program-row').find('[data-form-toggle-trigger]').removeClass('active')
    overlay.removeClass('active')
    overlay.css(
      left: 0,
      width: '100%'
    )

  closeOpenProgramRowOverlays: () ->
    $('.program-row .row-reveal.active').each (_, element) =>
      $overlay = $(element)
      $row = $overlay.closest('.program-row')
      @closeProgramRowOverlay($overlay, $row)

  closeOverlaysIfClickingElsewhere: (e) =>
    $row = $(e.target).closest('.program-row')
    unless ($row.length > 0) && ($row.find('.row-reveal.active').length > 0)
      @closeOpenProgramRowOverlays()

export default ToggleFormRow
