class RatingSlider
  @init: () ->
    new RatingSlider

  constructor: () ->
    firstPageLoad = true
    @sliderChange = window.debounce((sliderValue, loadedValue) =>
      if firstPageLoad
        firstPageLoad = false
      else
        @revealFocusForm(sliderValue, loadedValue)
    , 250)

    @attachEvents()

  attachEvents: () =>
    $('.slider.timeline .dates li').each (_, thing) =>
      itemWidth = $(thing).outerWidth() / 2 + 2
      rightValue = $(thing).data('proportion')
      $(thing).css({'position': 'absolute', 'right': 'calc(' + rightValue + ' - ' + itemWidth + 'px)'})

    loadedValue = parseInt($('.slider.focus-value').children('input').val())
    dimensionPosition = $('.slider.focus-value').data('dimension-position')

    $('.slider.focus-value').on 'moved.zf.slider', (e) =>
      $slider = $(e.target)
      sliderValue = parseInt($slider.children('input').val())
      sliderMaxValue = parseInt($slider.data('max'))
      @valueDotchange(sliderValue, sliderMaxValue, $slider)
      @sliderChange(sliderValue, loadedValue)

      if ($('.focus-wheel').length > 0)
        @focusPointPosition(dimensionPosition, sliderValue)

    $('.slider.focus-value .slider-handle').on 'click', (e) =>
      $slider = $(e.target).closest('.slider')
      sliderValue = parseInt($slider.children('input').val())
      @revealFocusForm(sliderValue, loadedValue)

    $('.slider.timeline').on 'moved.zf.slider', (e) =>
      sliderValue = parseInt($('.slider.timeline').children('input').val())

      startDate = $('.slider.timeline').data('start-date')
      date = new Date(startDate)
      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0)
      date.setDate(date.getDate() + sliderValue)

      data = $('#focus-wheel canvas').data('wheel-data')
      dates = $.map(data, (item) ->
        d = new Date(item['date'])
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0)
      )

      index_to_render = 0

      for i in [1..dates.length]
        if (date.valueOf() >= dates[i-1].valueOf())
          index_to_render = i-1

      focus_wheel.render(data[index_to_render]['dimensions'])

      @updateSidebar(sliderValue, date)


  updateSidebar: (sliderValue, setDate) ->
    if sliderValue + '' == $('.slider.timeline').attr('data-end')
      $('.focus-wheel-sidebar').find('.my-goal, .focus-areas, .focus-reorder').removeClass('hide')
      $('.focus-wheel-sidebar').find('.progress-history').addClass('hide')
    else
      $('.focus-wheel-sidebar').find('.my-goal, .focus-areas, .focus-reorder').addClass('hide')
      $('.focus-wheel-sidebar').find('.progress-history').removeClass('hide')

    dates = JSON.parse($('.focus-wheel-sidebar .progress-items').attr('data-dates'))

    dateToScrollTo = dates[0]
    setDate = parseInt(setDate.toISOString().substr(0, 10).replace(/-/g, ''))

    $.each dates, (_, date) ->
      if date < setDate
        dateToScrollTo = date
        return false

    $container = $('.progress-items')
    $firstItem = $('.progress-items .progress-item:first')
    $targetItem = $(".progress-items .progress-item[data-date='#{dateToScrollTo}']")

    if $targetItem.length > 0
      scrollOffset = ($targetItem.offset().top - $container.offset().top) - ($firstItem.offset().top - $container.offset().top)
      $('.focus-wheel-sidebar .progress-items').scrollTop(scrollOffset)

  valueDotchange: (sliderValue, sliderMaxValue, sliderContext) ->
    $(sliderContext).find('.value').removeClass (index, className) ->
      (className.match(/(^|\s)scale--\S+/g) || []).join(' ')

    if ($(sliderContext).find('.example-value'))
      $(sliderContext).find('.example-value').html(sliderValue)

    $(sliderContext).find('.value').html(sliderValue).addClass('scale--' + sliderValue)

    if (sliderValue == sliderMaxValue)
      $(sliderContext).find('.slider-legend i').addClass('complete')
    else
      $(sliderContext).find('.slider-legend i').removeClass('complete')

  focusPointPosition: (dimensionIndex, sliderValue) ->
    window.focus_wheel.renderNewDimensionValue(dimensionIndex, sliderValue)

  revealFocusForm: (sliderValue, loadedValue) ->
    $('.progress-update-desc').addClass('active')
    $('.progress-update-desc .upload-textarea textarea').focus()

    if (sliderValue > loadedValue)
      $('.progress-update-desc .increase').show()
      $('.progress-update-desc .decrease').hide()
    else
      $('.progress-update-desc .increase').hide()
      $('.progress-update-desc .decrease').show()

  hideFocusForm: () ->
    $('.progress-update-desc').removeClass('active')


export default RatingSlider
