class SelectInputRowChange
  @init: () ->
    new SelectInputRowChange

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    self = @

    $('.select_tag_switch').change (e) ->
      @dataVal = $(this).val()
      @dataType = $(this).data('switch-type')

      if @dataType == 'habit'
        self.habitSpecific(@dataVal)
      else
        $('[data-form-reveal]').removeClass('active');
        $('[data-form-reveal] input').attr('required': false)
        $('[data-form-reveal="'+@dataVal+'"] input').attr('required': true)
        $('[data-form-reveal="'+@dataVal+'"]').addClass('active')


  habitSpecific: (switchAttr) ->
    if switchAttr == 'specific_days'
      $('[data-form-reveal]').removeClass('active');
      $('[data-form-reveal="specific_days"]').addClass('active')
    else if switchAttr == ''
      $('[data-form-reveal]').removeClass('active');
    else
      $('[data-form-reveal="specific_days"]').removeClass('active')
      $('[data-form-reveal="at_least_most"]').addClass('active')
      $('[data-form-reveal="at_least_most"] label[data-form-reveal="'+switchAttr+'"]').addClass('active')
      $('[data-form-reveal="at_least_most"] label:not([data-form-reveal="'+switchAttr+'"])').removeClass('active')
export default SelectInputRowChange
