import * as Trix from "trix";

Trix.config.textAttributes.underline = {
	style:       {"textDecoration": "underline"},
	inheritable: true,
};

// Create a temporary DOM representation of the HTML string, to make processing easier
let wrapper = document.createElement('div');
wrapper.innerHTML = Trix.config.toolbar.getDefaultHTML();


let buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" tabindex="-1" title="Underline">U</button>';
let targetElement = wrapper.querySelector(".trix-button-group--text-tools .trix-button--icon-strike");
targetElement.insertAdjacentHTML("beforebegin", buttonHTML);

// Override the Trix toolbar html to include the custom buttons
Trix.config.toolbar.getDefaultHTML = function () {
	return wrapper.innerHTML;
};
