class HabitCheckboxes
  @init: () ->
    new HabitCheckboxes

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.habit-checkbox').on 'change', @sendAjax

  sendAjax: (e) ->
    element = $(e.target)
    url = element.data('url')
    date = element.data('date')
    day = element.data('day')
    completed = element.is(':checked')

    data = {
      date: date,
      completed: completed,
      day: day
    }

    $.post(url, data)

export default HabitCheckboxes
