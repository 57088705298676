class StarRatingReset
  @init: () ->
    new StarRatingReset

  constructor: () ->
    @attachEvents()
  attachEvents: () =>
    rating = new URL(window.location.href)
    if rating.searchParams.get('rating')
      $('.reflections-reset').removeClass('hide')

export default StarRatingReset
