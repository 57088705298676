import Canvas from 'javascripts/app/canvas'
import FocusWheelLabels from 'javascripts/app/focus_wheel_labels'

class FocusWheel
  @init: (container) ->
    new FocusWheel(container)

  constructor: (container) ->
    @container = container
    @attachEvents()
    @setUpCanvas()
    @debug = false

  attachEvents: () ->
    $(window).resize =>
      @resize()

  setUpCanvas: () ->
    @canvas = new Canvas(@container.find('canvas')[0])
    @context = @canvas.context
    @setSizes()

  setSizes: () ->
    @width = @canvas.width
    @height = @canvas.height
    @centerX = @canvas.centerX
    @centerY = @canvas.centerY

  render: (dimensions = []) ->
    if @canvas.displayNewDimension()
      if dimensions.length == 0 || !dimensions[dimensions.length - 1]['new']
        dimensions.push({name: 'New', rating: 0, new: true, id: 'new'})

    @renderedDimensions = dimensions
    @canvas.reset()
    @renderWheelBase(dimensions)
    @renderDimensionBase(dimensions)
    @renderFocusShape(dimensions)
    @renderDimensionPoints(dimensions)
    @renderLabels(dimensions)

  renderNewDimensionValue: (position, value) ->
    if @renderedDimensions
      index = @renderedDimensions.findIndex (d) ->
        d['position'] == position
      @renderedDimensions[index]['rating'] = value
    else
      @renderedDimensions = [{ rating: value }]

    @render(@renderedDimensions)

  resize: () ->
    @canvas.setUpCanvas()
    if @width != @canvas.width
      @setSizes()
      @render(@renderedDimensions)

  renderWheelBase: (dimensions) ->
    @canvas.strokeCircle(@centerX, @centerY, (@width*0.45), '#4F7FE1')
    @canvas.fillCircle(@centerX, @centerY, 4, '#4F7FE1')

  renderDimensionBase: (dimensions) ->
    angle = (2 * Math.PI) / dimensions.length

    dimensions.forEach (dimension, index) =>
      @canvas.rotateAroundCenter(angle * index)
      @canvas.strokeLine(@centerX, 0, @centerX, @centerY, '#4F7FE1')
      @canvas.rotateAroundCenter(-angle * index)

  renderFocusShape: (dimensions) ->
    angle = (2 * Math.PI) / dimensions.length

    @context.beginPath()

    dimensions.forEach (dimension, index) =>
      @canvas.rotateAroundCenter(angle * index)

      yPosition = (@centerY * 0.1) + ((@centerY * 0.9) / 10) * (10 - dimension['rating'])
      @context.lineTo(@centerX, yPosition)

      @canvas.rotateAroundCenter(-angle * index)

    @context.closePath()
    @context.stroke()
    @context.fillStyle = 'rgba(79, 127, 225, 0.3)'
    @context.fill()

  renderDimensionPoints: (dimensions) ->
    angle = (2 * Math.PI) / dimensions.length

    dimensions.forEach (dimension, index) =>
      @canvas.rotateAroundCenter(angle * index)

      yPosition = (@centerY * 0.1) + ((@centerY * 0.9) / 10) * (10 - dimension['rating'])
      @canvas.fillCircle(@centerX, yPosition, 9, '#292C44')

      @canvas.rotateAroundPoint(@centerX, yPosition, -angle * index)

      @context.font = 'bold 13px museo-sans-rounded'
      @context.fillStyle = 'white'

      if dimension['rating'] == 10
        @context.fillText(dimension['rating'], @centerX - 6, yPosition + 4.5)
      else
        @context.fillText(dimension['rating'], @centerX - 4, yPosition + 4.5)

      if @debug
        @context.fillStyle = 'black'
        @context.fillText(dimension['name'], @centerX + 10, yPosition + 10)

      @canvas.rotateAroundPoint(@centerX, yPosition, angle * index)
      @canvas.rotateAroundCenter(-angle * index)

  renderLabels: (dimensions) ->
    labels = new FocusWheelLabels(dimensions, @width)
    labels.render()

export default FocusWheel
