class OffCanvasMenu
  @init: () ->
    new OffCanvasMenu

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.off-canvas').on 'opened.zf.offcanvas', () ->
      $('body').addClass('off-canvas-menu-active')

    $('.off-canvas').on 'closed.zf.offcanvas', () ->
      $('body').removeClass('off-canvas-menu-active')

export default OffCanvasMenu
