class DeleteConfirm
  @init: () ->
    new DeleteConfirm

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('[data-delete-confirm-button]').click (e) ->
      e.preventDefault()
      dataVal = $(this).data('delete-confirm-button')
      $('[data-delete-confirm-wrapper="'+dataVal+'"]').addClass('active')
    $('.cancel-delete-confirm').click (e) ->
      e.preventDefault()
      $(this).parents('[data-delete-confirm-wrapper]').removeClass('active')
export default DeleteConfirm
