class DimensionImageUpload
  @init: () ->
    new DimensionImageUpload

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('input[type=file].upload-image').change () ->
      fileName = $(@).val().replace("C:\\fakepath\\", "")
      $('.filename').html(fileName)
      


export default DimensionImageUpload
