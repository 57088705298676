class ResourcesFileUpload
  @init: () ->
    new ResourcesFileUpload
   constructor: () ->
    @attachEvents()
   attachEvents: () ->
    # $('input[type=file]').change (event) ->
    #
    #   wrapper = $(this).parent('.upload-wrapper')
    #   fileName = $(@).val().replace("C:\\fakepath\\", "")
    #   input = $(event.currentTarget)
    #   file = input[0].files[0]
    #   reader = new FileReader
    #   wrapper.find('.filename').html(fileName)
    #
    #   reader.onload = (e) ->
    #     if file.type.match('image.*')
    #       image_base64 = e.target.result
    #       wrapper.find('.upload-preview').removeClass('default-icon').html('<img src="'+image_base64+'">')
    #       return
    #     else
    #       wrapper.find('.upload-preview').html('').addClass('default-icon')
    #       return
    #
    #   reader.readAsDataURL file
      return

 export default ResourcesFileUpload
