class FilterBySelect
  @init: () ->
    new FilterBySelect

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.filter-by-select--select_tag select').on 'change', (e) =>
      selector = $(e.target).val()
      @filter(selector)

  filter: (selector) ->
    $items = $('.filter-by-list').find('li')
    $items.removeClass('hide')
    $items.not(selector).addClass('hide')

export default FilterBySelect
