class ResourcesURLHttpPrepend
  @init: () ->
    new ResourcesURLHttpPrepend

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    self = @
    $('#resource_url').on 'input', (event) ->
      string = event.target.value;
      if (string.length > 0 && string.match(/\w+\.\w+/) && !string.match(/^https?:\/\//))
        string = "http://" + string;
        event.target.value = string;


export default ResourcesURLHttpPrepend

