class TimesheetsTable
  @init: () ->
    new TimesheetsTable

  constructor: () ->
    return unless $('.timesheets_list').length > 0
    @attachEvents()
    @updateTableTotals()
    @openProgramIds = (window.localStorage.getItem('timesheetOpenProgramIds') || '').split(' ')
    @hideAllTimesheetEntries(true)
    @showStoredTimesheetEntries()

  attachEvents: () ->
    $(document).on 'keydown change', '.timesheet-invoice-reference', debounce(@updateInvoiceReference, 600)
    $(document).on 'keydown change', '.timesheet-invoiced-total', debounce(@updateInvoicedTotal, 1200)
    $(document).on 'blur', '.timesheet-invoice-reference', @updateInvoiceReference
    $(document).on 'blur', '.timesheet-invoiced-total', @updateInvoicedTotal

    $(document).on 'click', '.notes-link', (e) ->
      e.preventDefault()
      $(e.target).closest('tr').find('.title .notes').toggleClass('hide')

    $('.timesheet-program-summary').on 'click', @toggleTimesheetEntries

    $('table.timesheets_list').on 'beforeSort', () =>
      @hideAllTimesheetEntries(true)
      @hideTotals()

    $('table.timesheets_list').on 'afterSort', () =>
      @showStoredTimesheetEntries()
      @showTotals()

    $('table.timesheets_list').on 'filteredByTag', @updateTableTotals

  updateInvoiceReference: (e) =>
    $row = $(e.target).closest('tr')
    reference = $row.find('input[name=invoice_reference]').val()
    data = { invoice_reference: reference }
    @redrawRow($row, data)

  updateInvoicedTotal: (e) =>
    $row = $(e.target).closest('tr')
    total = $row.find('input[name=invoiced_total]').val()
    data = { invoiced_total: total }
    @redrawRow($row, data)

  redrawRow: ($row, data) ->
    url = $row.attr('data-url')

    $row.find('input').attr('disabled', true)
    $.post(url, data, (response) ->
      $program = $row.closest('.timesheet-program-entries').prev()
      $row.replaceWith(response.timesheet_row)
      $program.find('.invoiced').text(response.invoiced_total.toFixed(2))
      $program.find('.uninvoiced').text(response.uninvoiced_total.toFixed(2))
    )

    @updateTableTotals()

  hideTotals: () =>
    $('.timesheets_list .totals').appendTo($('#timesheets_placeholder'))

  showTotals: () =>
    $('#timesheets_placeholder .totals').appendTo($('.timesheets_list > tbody'))

  updateTableTotals: () =>
    if $('.timesheet-program-summary .invoiced:visible').length > 0
      invoiced_total = $('.timesheet-program-summary .invoiced:visible').map((i, e) -> Number(e.innerHTML)).get().reduce((sum, x) -> sum + x)
      uninvoiced_total = $('.timesheet-program-summary .uninvoiced:visible').map((i, e) -> Number(e.innerHTML)).get().reduce((sum, x) -> sum + x)
    else
      invoiced_total = 0
      uninvoiced_total = 0

    $('.totals .invoiced').text(invoiced_total.toFixed(2))
    $('.totals .uninvoiced').text(uninvoiced_total.toFixed(2))

  toggleTimesheetEntries: (e) =>
    return if $(e.target).hasClass('button')
    $summary = $(e.target).closest('.timesheet-program-summary')
    program_id = $summary.attr('data-program-id')
    if $summary.hasClass('active')
      @hideTimesheetEntries(program_id)
    else
      @showTimesheetEntries(program_id)

  showTimesheetEntries: (program_id, skip_local_storage = false) =>
    $summary = $(".timesheet-program-summary[data-program-id=#{program_id}]")
    $summary.addClass('active')
    $(".timesheet-program-entries[data-program-id=#{program_id}]").addClass('active').insertAfter($summary)
    unless skip_local_storage
      @openProgramIds.push(program_id) if @openProgramIds.indexOf(program_id) == -1
      @updateLocalStorage()

  hideTimesheetEntries: (program_id, skip_local_storage = false) =>
    $summary = $(".timesheet-program-summary[data-program-id=#{program_id}]")
    $summary.removeClass('active')
    $(".timesheet-program-entries[data-program-id=#{program_id}]").appendTo($('#timesheets_placeholder'))
    unless skip_local_storage
      if (index = @openProgramIds.indexOf(program_id)) > -1
        @openProgramIds.splice(index, 1)
      @updateLocalStorage()

  hideAllTimesheetEntries: (skip_local_storage = false) =>
    $('.timesheet-program-summary').each (i, el) =>
      program_id = $(el).attr('data-program-id')
      @hideTimesheetEntries(program_id, skip_local_storage)

  showStoredTimesheetEntries: () =>
    @openProgramIds.forEach (program_id) =>
      return if program_id == ''
      @showTimesheetEntries(program_id)

  updateLocalStorage: () =>
    window.localStorage.setItem('timesheetOpenProgramIds', @openProgramIds.join(' '))

export default TimesheetsTable
