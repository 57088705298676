class ProgramNavigationLayout
  @init: () ->
    new ProgramNavigationLayout

  constructor: () ->
    if $('.edit-goal').length > 0
      @attachEvents()
      @tidyUpNavigationBar()

  attachEvents: () ->
    $(window).on 'resize', debounce(@tidyUpNavigationBar, 250)

  tidyUpNavigationBar: () ->
    textPosition = $('.program-title-text').position().left
    buttonPosition = $('.edit-goal').position().left

    difference = buttonPosition - textPosition
    if difference < 10
      $('.program-title').addClass('fix-alignment')
    else
      $('.program-title').removeClass('fix-alignment')

export default ProgramNavigationLayout
