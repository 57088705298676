class Subscriptions

  @redirectToCheckout: (sessionId) ->
    @stripe = window.Stripe(process.env.STRIPE_PUBLIC_KEY)
    @stripe.redirectToCheckout({
      sessionId: sessionId
    })

  @waitForState: (attempt=1)->
    if attempt == 1
      # set up
      $('.global_navigation').remove()
      @state = $('#subscription_wait_for').data('state')

    if attempt >= 30
      # give up, reload with timeout state
      window.location = window.location.pathname + '?timeout'

    $.get '/subscription/current_state', (data) =>
      if @state.indexOf(data.state) >= 0
        # found a desired state, reload with success state
        window.location = window.location.pathname + "?success"
      else
        # desired state not found, try again soon
        setTimeout((->Subscriptions.waitForState(attempt+1)), 1000)

export default Subscriptions
