import {ready} from 'javascripts/app/dynamic_ready';

import * as Trix from "trix";


// Create a temporary DOM representation of the HTML string, to make processing easier
let wrapper = document.createElement('div');
wrapper.innerHTML = Trix.config.toolbar.getDefaultHTML();

let ToolTip = `
<p style="white-space: normal; overflow: hidden; margin-top: 5px;"><b>Note: </b> Please ensure that your link has http/https at the start</p>
<p style="white-space: normal; overflow: hidden; word-break: break-all">e.g. '<b>http://www.example.com/something/something'</b></p>
`;

let targetElement = wrapper.querySelector(".trix-dialogs .trix-dialog--link");
targetElement.insertAdjacentHTML("beforeend", ToolTip);

// Override the Trix toolbar html to include the custom buttons
Trix.config.toolbar.getDefaultHTML = function () {
	return wrapper.innerHTML;
};


/*
 As follows is a horrible hack to make the Trix AddLink dialogue prepend http:// if not already present.
 The Trix editor uses Stimulus JS controllers, which upon mount make some JS changes to the dom.
 Namely adding onblur and onfocus events that modify the placeholder of the Link.

 To override that we need to wait for the change to happen, and then do our changes after, go figure

 TODO: If and when Trix adds a customisation API, use that instead of this
 */
let eventHandler = function (event) {
	let string = event.target.value;
	if (string.length > 0 && string.match(/\w+\.\w+/) && !string.match(/^https?:\/\//)) {
		string = "http://" + string;
		event.target.value = string;
	} else if (string.length === 0) {
		event.target.placeholder = ''; // Preserve Trix default behaviour
	} else if (string.length === 0) {
		event.target.placeholder = 'Enter a URL...'; // Preserve Trix default behaviour
	}
};

// When a mutation happens, process and filter it
let mutationCallback = function (mutationList, observer) {
	// Loop over mutations to the DOM
	for (let x = 0; x < mutationList.length; x++) {
		let mutation = mutationList[x];
		let target = mutation.target;
		// If the mutation is a change to the attributes of an element, and that element is `.trix-input--dialog
		// input[type="url"]`, and the attribute changed is the 'onblur' attribute
		if (target.classList.contains("trix-input--dialog")
			&& target.type === "url"
			&& mutation.type === 'attributes'
			&& mutation.attributeName === "onblur") {
			observer.disconnect();

			// Bind event handlers
			target.onblur = eventHandler;
			target.addEventListener('input', eventHandler);
		}
	}
};

// Only when the trix-editor is present in the DOM (Note: Not a one time thing, every time an instance of a
// <trix-editor> is appended to the DOM too)
ready('trix-toolbar', function () {
	// Listen for onblur attribute changes anywhere in the descendant tree of <trix-toolbar>
	new MutationObserver(mutationCallback).observe(document.querySelector('trix-toolbar'), {
		attributes: true,
		// attributeFilter: ['onblur', 'on'],
		childList:  true,
		subtree:    true,
	});
});
