import Tablesort from 'tablesort'

class TableSortWithAddons
  @init: ->
    Tablesort.extend('number', @match_number, @compare_number)
    Tablesort.extend('reverse_number', @match_number, @compare_number_reverse)

    for table in $('table[data-sort-table]')
      continue if $(table).data('initialised')

      $(table).data('initialised', true)
      Tablesort(table)

    @attachEvents()

  @match_number: (item) ->
    item.match(/^[-+]?[£\x24Û¢´€]?\d+\s*([,\.]\d{0,2})/) || # Prefixed currency
    item.match(/^[-+]?\d+\s*([,\.]\d{0,2})?[£\x24Û¢´€]/) || # Suffixed currency
    item.match(/^[-+]?(\d)*-?([,\.]){0,1}-?(\d)+([E,e][\-+][\d]+)?%?$/) # Number

  @clean_number: (i) ->
    i.replace(/[^\-?0-9.]/g, '')

  @compare_number: (b, a) =>
    a = parseFloat(@clean_number(a))
    b = parseFloat(@clean_number(b))

    a = if isNaN(a) then 0 else a
    b = if isNaN(b) then 0 else b

    a - b

  @compare_number_reverse: (b, a) =>
    @compare_number(a,  b)

  @attachEvents: () ->
    $('table').on 'afterSort', (e) ->
      $(e.target).find('.row-reveal, .extra-options-toggle').removeClass('active')

export default TableSortWithAddons
