class BillingPlanChoice
  @init: () ->
    new BillingPlanChoice

  constructor: () ->
    @attachEvents()
    @checkCurrentPlan()

  attachEvents: () ->
    $('.billing-plans-item').click (e) =>
      selectedBillingPlan = $(e.target.closest('.billing-plans-item')).data('plan-id')
      @changePlan(selectedBillingPlan)


  checkCurrentPlan: () ->
    selectedBillingPlan = $('.billing-plan-radios input[type="radio"]:checked').val()
    $('.billing-plans-item').removeClass('current-plan')
    $('.billing-plans-item[data-plan-id="'+selectedBillingPlan+'"]').addClass('current-plan')

  changePlan: (planId) ->
    $('.billing-plans-item').removeClass('active')
    $('.billing-plans-item[data-plan-id="'+planId+'"]').addClass('active')
    $('.billing-plan-radios input[type="radio"]').attr('checked', false);
    $('.billing-plan-radios #billing_plan_id_'+planId).attr('checked', true);
    $('input[type="submit"]').attr('disabled', false)
export default BillingPlanChoice
