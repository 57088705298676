class FileUpload
  @init: () ->
    new FileUpload
   constructor: () ->
    @attachEvents()
   attachEvents: () ->
    $('input[type=file]').change (event) =>
      if $(event.target).val() != ''
        wrapper = $(event.target).parent('.upload-wrapper')
        fileName = $(event.target).val().replace("C:\\fakepath\\", "")

        input = $(event.currentTarget)
        file = input[0].files[0]
        reader = new FileReader
        previewImageUpload = $(event.target).data('preview-id')

        wrapper.find('.reset-upload').removeClass('hide')
        wrapper.find('.filename').html(fileName)
        wrapper.find('.upload-label .button').html('Change file')
        wrapper.find('.upload-image-save').removeClass('hide')

        reader.onload = (e) ->
          if file.type.match('image.*')
            image_base64 = e.target.result
            if previewImageUpload != false
              $('[data-preview-id="' + previewImageUpload + '"]').removeClass('default-icon').html('<img src="'+image_base64+'">')
            else
              wrapper.find('.upload-preview').removeClass('default-icon').html('<img src="'+image_base64+'">')
            return
          else
            if previewImageUpload != false
              $('[data-preview-id="' + previewImageUpload + '"]').html('').addClass('default-icon')
            else
              wrapper.find('.upload-preview').html('').addClass('default-icon')
            return

        reader.readAsDataURL file
        return



      else
        @resetUpload(event.target)

    $('.button.reset-upload').click (e) =>
      @resetUpload(e.target)

  resetUpload: (item) =>
    wrapper = $(item).parents('.upload-wrapper')
    previewImageUpload = wrapper.find('[data-preview-id]').data('preview-id')
    button_text = wrapper.find('[data-button-text]').data('button-text')

    wrapper.find('.reset-upload').addClass('hide')
    wrapper.find('.upload-image-save').addClass('hide')

    wrapper.find('.filename').html('')
    wrapper.find('.upload-label .button').html(button_text)

    if previewImageUpload != false
      $('[data-preview-id="' + previewImageUpload + '"]').removeClass('default-icon').html('')
    else
      wrapper.find('.upload-preview').removeClass('default-icon').html('')

 export default FileUpload
