class TeamMemberRoleUpdate
  @init: () ->
    new TeamMemberRoleUpdate

  constructor: () ->
    @attachEvents()

  attachEvents: () ->

    $('.edit_program_membership input[type="text"]').keyup () ->
      parentForm = $(this).parents('.edit_program_membership')
      $(parentForm).find('input[type="submit"]').prop('disabled', false);

    $('.edit_program_membership select').change () ->
      parentForm = $(this).parents('.edit_program_membership')
      $(parentForm).find('input[type="submit"]').prop('disabled', false);



export default TeamMemberRoleUpdate
