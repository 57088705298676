class SupportItems
  @init: () ->
    new SupportItems

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.load-all-support-items').on 'ajax:success', @loadAllItems

  loadAllItems: (e) =>
    [data, status, xhr] = e.detail
    $('.chats_resources').replaceWith($(data).find('.chats_resources'))
    $(e.target).remove()

export default SupportItems
