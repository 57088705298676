class FilterFocusDimension
  @init: () ->
    new FilterFocusDimension

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.filter-focus-dimension select').on 'change', (e) ->
      e.target.form.submit()

export default FilterFocusDimension
