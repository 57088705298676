import TableSortWithAddons from 'javascripts/app/table_sort_with_addons'

class SearchResults
  @init: ->
    new SearchResults

  constructor: ->
    @form = $('[data-search]')

    if @form.length > 0
      @search = @form.find('.search-field')
      @results = $('[data-search-results]')
      @attachEvents()

  attachEvents: ->
    @form.on 'ajax:before', @startLoading
    @form.on 'ajax:complete', @finishLoading
    @form.on 'ajax:success', @updateResults
    @search.on 'click', 'i.clear', @clearField

  startLoading: =>
    @search.addClass('loading')

  finishLoading: =>
    setTimeout((() => @search.removeClass('loading')), 500)

  updateResults: (e) =>
    @results.find('table').replaceWith($(e.detail[0]).find('table'))
    @search.addClass('filtered') if @search.find('input').val() != ''
    TableSortWithAddons.init()
    $(document).trigger('tagRowsChanged')
    $(document).trigger('filterByStoredTags')

  clearField: (e) =>
    @search.find('input').val('')
    @search.removeClass('filtered')
    Rails.fire(@form[0], 'submit')

 export default SearchResults
