class CalloutTimeout
  @init: () ->
    new CalloutTimeout

  constructor: () ->
    @attachEvents()

  attachEvents: () =>
    self = @
    if $('.callout').length > 0
      setTimeout self.fadeOutCallouts, 2500

  fadeOutCallouts: () ->
    $('.callout').fadeOut()

export default CalloutTimeout
