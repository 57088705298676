class NewFocusForm
  @init: () ->
    new NewFocusForm

  constructor: () ->
    if $('.focus-form-section').length > 0
      @attachEvents()
      $('.new-dimension').html('Example Label')
      $('.focus-wheel-slider').cycle();
  attachEvents: () =>
    # sets the correct content of @
    self = @


    # sets up the functions to fire when the nav buttons are called
    $('.next-step').click =>
      @nextStep()
    $('.prev-step').click =>
      @prevStep()

    #We grab hold of the enter key and help the user navigate with it.
    $(document).keypress (e) =>
      currSlide = $('.cycle-slideshow').data('cycle.API').opts().currSlide

      if e.which == 13 and $('.focus-form-section').length > 0 && $('.large-description-field:focus').length == 0
        e.preventDefault()

        if currSlide == 3
          $('input[type="submit"]').click()
        else
          @nextStep()

    # this adjusts the focus wheel value when a user enters a new label. it
    # also resets it if the value is empty
    $('#focus_dimension_form_name').keyup ->
      inputValue = $(this).val()
      if inputValue != ''
        $('.dimension-label .new-dimension').html inputValue
      else
        $('.dimension-label .new-dimension').html "Example Label"

  #functions thats fired when the next button is clicked.
  nextStep: () =>
    dir = 'forward'
    sectionInputCount = $('.cycle-slide-active input').length
    currSlide = $('.cycle-slideshow').data('cycle.API').opts().currSlide + 1
    slideCount = $('.cycle-slideshow').data('cycle.API').opts().slideCount
    inputValidation sectionInputCount, currSlide, slideCount, dir
    if currSlide == 1
      $('.focus-wheel-slider').cycle 'next'

  #functions thats fired when the prev button is clicked.
  prevStep: () =>
    dir = 'back'
    currSlide = $('.cycle-slideshow').data('cycle.API').opts().currSlide + 1
    slideCount = $('.cycle-slideshow').data('cycle.API').opts().slideCount
    prevButton currSlide, slideCount, dir
    nextButton currSlide, slideCount, dir

    if currSlide == 2
      $('.prev-step').addClass 'hide'
      $('.cycle-slideshow').cycle 'prev'
      $('.focus-wheel-slider').cycle 'prev'
    else
      $('.cycle-slideshow').cycle 'prev'

  #sets the correct prev and next buttons depending on slider progression.
  prevButton = (currSlide, dir) =>
    if currSlide == 1
      $('.prev-step').removeClass 'hide'
  nextButton = (currSlide, slideCount, dir) =>
    if currSlide == 2 and dir != 'back'
      $('.next-step').addClass 'hide'
    else
      $('.next-step').removeClass 'hide'


  # function to check if there are any validation required inputs on a particular
  # form page.
  inputValidation = (sectionInputCount, currSlide, slideCount, dir) ->

    if sectionInputCount > 0
      i = 0

      inputs = $('.cycle-slide-active input, .cycle-slide-active textarea')
      inputs.each (element) ->
        if !@checkValidity()
          $('#new_focus_dimension_form').find(':submit').click()
          return
        else
          i++
        if i == inputs.length
          $('.cycle-slideshow').cycle 'next'
          prevButton currSlide, slideCount, dir
          nextButton currSlide, slideCount, dir
    else
      $('.cycle-slideshow').cycle 'next'
      prevButton currSlide, slideCount, dir
      nextButton currSlide, slideCount, dir




export default NewFocusForm
