class SortableList
  @init: () ->
    new SortableList

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.edit-dimension-list').sortable({ 'axis': 'y', 'handle': '.handle' })
    $('.sort-reflections-list').sortable({ 'handle': '.handle' })

    $('.edit-dimension-list').on 'sortupdate', @updatePositions
    $('.sort-reflections-list').on 'sortupdate', @updatePositions

  updatePositions: (e) ->
    position = 0
    $(e.target).find('> li').each () ->
      position++
      $(this).find('.position-field').val(position)

export default SortableList
