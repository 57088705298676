class TaggableTags
  @init: () ->
    new TaggableTags

  constructor: () ->
    @setValidTags()
    @attachEvents()
    @setupOverlayClasses()
    $(document).trigger('filterByStoredTags')

  setValidTags: =>
    tags = []
    for row in $('.taggable-row')
      tags = tags.concat($(row).find('[data-tags]').attr('data-tags')?.split(' ') || new Array)

    @validTags = @_uniqueArray(tags)
    @disableTagsWithoutRows()

  getAllTags: ->
    tags = $('.taggable-tags-filters').attr('data-tags')?.split(' ') || new Array
    @_uniqueArray(tags)

  _uniqueArray: (arr) ->
    unique_values = []

    for i in [0...arr.length]
      if unique_values.indexOf(arr[i]) == -1 && arr[i] != ''
        unique_values.push(arr[i])

    unique_values

  disableTagsWithoutRows: ->
    all_tags = @getAllTags()

    for tag in all_tags
      if @validTags.indexOf(tag) == -1
        # no row for tag filter
        $('.taggable-tags-filters [data-tag="'+tag+'"]').attr('disabled', 'disabled')
      else
        $('.taggable-tags-filters [data-tag="'+tag+'"]').removeAttr('disabled')

  attachEvents: () ->
    $(document).on 'ajax:success', '.taggable-tag-form', @redrawTagsPartial
    $(document).on 'click', '.open-taggable-tags', @openTaggableTags
    $(document).on 'mousedown', @closeTaggableTags
    $(document).on 'click', '.taggable-tags-filters [data-tag]', @handleTagClick
    $(document).on 'filterByStoredTags', @filterTaggablesFromStorage
    $(document).on 'tagRowsChanged', @setValidTags

  redrawTagsPartial: (e) =>
    $originalTags = $(e.target).closest('.taggable-tags')
    $parent = $originalTags.parent()

    $originalTags.replaceWith(e.detail[2].responseText)

    $newTags = $parent.find('.taggable-tags')
    $newTags.find('.taggable-tags-overlay').removeClass('hide')
    $input = $newTags.find('input[type=text]')
    $input.focus()

    @setupOverlayClasses()
    @updateTagFilters()
    @setupAutocomplete($input)

  openTaggableTags: (e) =>
    e.preventDefault()
    $('.taggable-tags-overlay').addClass('hide')
    $('.taggable-row').removeClass('highlight')

    $tags = $(e.target).closest('.taggable-tags')
    $tags.find('.taggable-tags-overlay').removeClass('hide')
    $tags.closest('.taggable-row').addClass('highlight')

    $input = $tags.find('input[type=text]')
    $input.focus()
    @setupAutocomplete($input)

  closeTaggableTags: (e) ->
    unless $(e.target).closest('.taggable-tags-overlay, .open-taggable-tags, .autocomplete-suggestions').length > 0
      $('.taggable-tags-overlay').addClass('hide')
      $('.taggable-row').removeClass('highlight')

  handleTagClick: (e) =>
    e.preventDefault()
    $(e.currentTarget).toggleClass('active')
    active_tags = $('.taggable-tags-filters [data-tag]').filter('.active').map( ->
      $(@).attr('data-tag')
    ).toArray()

    window.localStorage.setItem('activeTags', @validActiveTags(active_tags).join(' '))
    @filterTaggables(active_tags)

  validActiveTags: (active_tags) ->
    active_tags.filter (n) =>
      @validTags.indexOf(n) != -1

  filterTaggables: (active_tags) =>
    active_tags = @validActiveTags(active_tags)
    rows = $('.taggable-row')

    if active_tags.length > 0
      rows.each ->
        taggable_tags = $(@).find('[data-tags]').attr('data-tags')?.split(' ') || new Array
        window.taggable_tags = taggable_tags
        if active_tags.every((tag) -> taggable_tags.indexOf(tag) > -1)
          $(@).removeClass('hide')
        else
          $(@).addClass('hide')
    else
      rows.removeClass('hide')

    # mark empty
    rows.closest('tbody').toggleClass('empty', rows.filter('.hide').length == rows.length)

    $('.taggable-row').closest('table').trigger('filteredByTag')

    @setupOverlayClasses()

  filterTaggablesFromStorage: () =>
    active_tags = window.localStorage.getItem('activeTags')?.split(' ') || new Array
    if (index = active_tags.indexOf('')) > -1
      active_tags.splice(index, 1)

    $('.taggable-tags-filters [data-tag]').removeClass('active')
    active_tags.forEach (tag) ->
      $(".taggable-tags-filters [data-tag=#{tag}]").addClass('active')

    @filterTaggables(active_tags)

  setupOverlayClasses: () ->
    $('[data-tags]').removeClass('overlay-above')
    $('[data-tags]').removeClass('overlay-beside')
    $('[data-tags]:visible').slice(-2).addClass('overlay-above')

    if $('[data-tags]:visible').length <= 3
      $('[data-tags]:visible').first().removeClass('overlay-above').addClass('overlay-beside')

  setupAutocomplete: ($input) ->
    allTags = @getAllTags()
    currentTags = $input.closest('.taggable-tags').attr('data-tags')?.split(' ') || new Array
    availableTags = allTags.filter((tag) -> !currentTags.includes(tag))

    selector = "##{ $input.closest('.taggable-row').attr('id') } .taggable-tags-overlay input[type=text]"
    new autoComplete({
      minChars: 2,
      selector: selector,
      source: (term, suggest) ->
        term = term.toLowerCase()
        filteredTagNames = availableTags.filter((name) -> name.startsWith(term))
        suggest(filteredTagNames)
    })

  updateTagFilters: () ->
    url = $('.taggable-tags-filters-container').data('url')
    $.get url, (body) =>
      $filtersContainer = $('.taggable-tags-filters-container')
      $filtersContainer.replaceWith(body)
      @setValidTags()

export default TaggableTags
