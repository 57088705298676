import { DirectUpload } from "@rails/activestorage"

class ResourceFileUploader
  constructor: (file, url, row, controller) ->
    @row = row

    controller.disable_form()

    @upload = new DirectUpload(file, url, this)

    @upload.create (error, blob) =>
      if error
        alert("Sorry, an error occurred uploading this file:\n"+error)
      else
        @row.find('input[type="hidden"]').val(blob.signed_id)
        @row.removeClass('resource-uploading')

      controller.enable_form()

  directUploadWillStoreFileWithXHR: (request) =>
    request.upload.addEventListener('progress', @upload_progress)

  upload_progress: (event) =>
    @row.find('.resource-percent').text(Math.round((event.loaded / event.total) * 100)+"%")


class ResourcesFiles
  @init: ->
    new ResourcesFiles

  constructor: ->
    @container = $(".resources-files")
    @uploading = 0

    if @container.length > 0
      @table = @container.find('table.resources-table')
      @input = @container.find('input[type="file"].resources-upload')
      @submit = @input.closest('form').find('input[type="submit"]')
      @template = @container.find('template.resources-template')

      @attachEvents()

  attachEvents: ->
    @input.on 'change', @upload_files
    @table.find('.delete a').on 'ajax:success', @delete_resource_file
    @table.on 'click', '.delete.new a', @delete_resource_file

  upload_files: (event) =>
    Array.from(@input[0].files).forEach((file) => @upload_file(file))
    @input.val(null)

  upload_file: (file) ->
    # add new row, ahead of upload
    new_row = $(@template[0].content.firstElementChild.cloneNode(true))
    new_row.find('.resource-filename').text(file.name)
    @table.find('tbody').append(new_row)

    # preview file
    @preview_file(new_row, file)

    # begin upload
    url = @input.data('directUploadUrl')
    upload = new ResourceFileUploader(file, url, new_row, this)

  preview_file: (row, file) ->
    reader = new FileReader

    reader.onload = (e) ->
      if file.type.match('image.*')
        image_base64 = e.target.result
        row.find('.resource-preview').removeClass('default-icon').html('<img src="'+image_base64+'">')


    reader.readAsDataURL file

  delete_resource_file: (event) ->
    $(event.target).closest('tr').remove()

  disable_form: ->
    @uploading += 1
    @submit.prop('disabled', true)

  enable_form: ->
    @uploading -= 1
    @submit.prop('disabled', false) if @uploading == 0


 export default ResourcesFiles
