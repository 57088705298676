class CharacterCounter
  @init: () ->
    new CharacterCounter

  constructor: () ->
    if $('.character-count-input').length > 0
      @updateCounter($('.character-count-input'))
      @attachEvents()

  attachEvents: () ->
    $('.character-count-input').on 'keyup', (e) =>
      @updateCounter($(e.target))

  updateCounter: (element) ->
    count = element.val().length
    maxLength = element.attr('maxlength')
    remainingCount = maxLength - count
    element.parent().find('.character-count').text(remainingCount)

export default CharacterCounter
