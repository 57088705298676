class TimesheetCheckboxes
  @init: () ->
    new TimesheetCheckboxes

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.timesheet-invoiced-checkbox input').on 'change', @sendAjax

  sendAjax: (e) ->
    element = $(e.target)
    url = element.closest('form').attr('action')
    value = if element.is(':checked') then 1 else 0

    data = {
      invoiced: value
    }

    $.post(url, data)

export default TimesheetCheckboxes
