class FakeLinks
  @init: () ->
    new FakeLinks

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $(document).on 'click', '[data-behavior="fake-link"]', (e) ->
      return if $(e.target).closest('[data-behavior="skip-fake-link"]').length > 0
      url = $(e.target).closest('[data-behavior="fake-link"]').attr('data-link')
      window.location = url

export default FakeLinks
