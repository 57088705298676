class ReflectionFrequencySelects
  @init: () ->
    new ReflectionFrequencySelects

  constructor: () ->
    @attachEvents()
    @showOrHideOptions()

  attachEvents: () ->
    $('.frequency-select').on 'change', @showOrHideOptions
    @showOrHideOptions()

  showOrHideOptions: () ->
    if $('.frequency-select').length > 0
      value = $('.frequency-select').find('option:selected').attr('value')

      $('.frequency-values-select').find('option').detach().appendTo('.option-sink')
      $('.option-sink').find("option.frequency-#{value}-option").detach().appendTo('.frequency-values-select')
      $('.frequency-values-select').val($('.frequency-values-select').attr('data-selected'))

export default ReflectionFrequencySelects
