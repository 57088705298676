class ChatMessageScroll
  @init: () ->
    new ChatMessageScroll

  constructor: () ->
    @setValues()
    @attachEvents()
    @scrollToBottom()
    @handleScroll()

  setValues: () ->
    if $('.chat-messages-container .grid-x').length > 0
      @scrollHeight = $('.chat-messages-container .grid-x')[0].scrollHeight
      @height = $('.chat-messages-container .grid-x').height()
      @maxValue = @scrollHeight - @height
      @scrollPadding = 60

  attachEvents: () ->
    $('.chat-messages-container .grid-x').on 'scroll', @handleScroll
    $('.chat-messages-container .scroll-up').on 'click', @scrollUp
    $('.chat-messages-container .scroll-down').on 'click', @scrollDown

  handleScroll: () =>
    @scrollValue = $('.chat-messages-container .grid-x').scrollTop()
    if @scrollValue > 0
      $('.chat-messages-container').addClass('messages-above')
    else
      $('.chat-messages-container').removeClass('messages-above')

    if @scrollValue < @maxValue
      $('.chat-messages-container').addClass('messages-below')
    else
      $('.chat-messages-container').removeClass('messages-below')

  scrollToBottom: () =>
    $('.chat-messages-container .grid-x').scrollTop(@maxValue)

  scrollUp: () =>
    $('.chat-messages-container .grid-x').animate({ scrollTop: @scrollValue - @height + @scrollPadding})

  scrollDown: () =>
    $('.chat-messages-container .grid-x').animate({ scrollTop: @scrollValue + @height - @scrollPadding})

export default ChatMessageScroll
