class FocusSidebar
  @init: () ->
    new FocusSidebar

  constructor: () ->
    @attachEvents()

  attachEvents: () ->
    $('.focus-sidebar-list-item').on 'click', (e) ->
      $(e.currentTarget).find('.target_desc span').toggleClass('hide')

export default FocusSidebar
